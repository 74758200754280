import {useEffect} from "react";
import {useLocation, Route, Routes} from "react-router-dom";
import BrandPage from "./BrandPage"
import ReactGA from 'react-ga';

const Home = () => {
    const loc = useLocation()

    useEffect(() => {
      ReactGA.initialize('UA-154475603-12');
      ReactGA.pageview(window.location.pathname + window.location.search);
    }, [])

    useEffect(() => {
    }, [loc.search])

    return(
        <div
            className={"ib-bg"}
            style={{
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
              backgroundImage: "url(https://i.ibb.co/7j0VXK3/Rectanglebg.png)",
              backgroundAttachment: 'fixed',
              backgroundSize: 'cover',
            }}
        >
          <Routes>
            <Route
                path={"/:brand"}
                element={<BrandPage />}
            />
            <Route
                path={"*"}
                element={<p>404</p>}
            />
          </Routes>
        </div>
    )
}

export default Home