import {Steps} from "antd";
import {pageTypes} from "./BrandPage";

type StepComponentProps = {
  currPage: pageTypes,
  address: boolean,
  product: boolean,
  setCurr: Function
}

type StepItem = {
  id: number,
  title: string,
  key: string,
}

const addressStep: StepItem = {
  id: 2,
  title: "Shipping Address",
  key: "address"
}

const StepComponent = (
  {
    currPage,
    address,
    product,
    setCurr
  }: StepComponentProps
) => {

  const brandSteps: any[] = [
    {
      id: 0,
      title: "Your Details",
      key: "details",
    },
    product ? {
      id: 1,
      title: "Product",
      key: "product"
    } : {},
    address ? addressStep : {}
  ]

  function getCurr() {
    switch (currPage) {
      case "details": return 0;
      case "address": return product ? 2 : 1;
      case "product": return 1;
      default: return 0
    }
  }

  return(
    <div
      hidden={currPage === 'details'}
    >
      <Steps
        current={getCurr()}
        initial={0}
        onChange={e => setCurr(brandSteps[e].key)}
      >
        {brandSteps.filter(b => b.key).map((a) => (
          <Steps.Step
            key={a.key}
            title={a.title}
          />
        ))}
      </Steps>
    </div>
  )
}

export default StepComponent